<template>
  <div>
    <div
      class="filter-container"
    >
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label"
              for="stock"
            >Stock Transfer</label>
            <div class="uk-form-controls">
              <input
                id="stock_retur"
                v-model="metaFilter.stock_transfer"
                class="uk-input"
                type="text"
                placeholder="Cari stock transfer..."
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label"
              for="status"
            > Status </label>
            <div class="uk-form-controls">
              <multiselect
                id="status_retur"
                v-model="statusChoose"
                v-validate="'required'"
                name="status"
                label="name"
                track-by="name"
                placeholder="Semua"
                :options="returstatus"
                open-direction="bottom"
                :loading="isLoading"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
              />
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label"
              for="cari"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="cari_retur"
                v-model="metaFilter.search"
                class="uk-input"
                type="text"
                placeholder="Cari kode siklus"
              >
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
    <import-sapronak />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import ImportSapronak from './ImportSapronak'

export default {
  components:{
    ImportSapronak
  },
  data() {
    return {
      statusChoose: "",
      isLoading: false,
      metaFilter: {
        stock_transfer: "",
        status: "",
        search: ""
      },
      returstatus: [
        { name: "Delivered", value: "completed" },
        { name: "In Transit", value: "in_transit" },
        { name: "On Process", value: "draft" },
        { name: "Canceled", value: "rejected" }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meta: "sapronak/metaRetur"
    })
  },
  methods: {
    ...mapMutations({
      setMeta: "sapronak/SET_META_RETUR"
    }),
    handleFind() {
      this.metaFilter.page = 1
      this.metaFilter.status = this.statusChoose ? (this.statusChoose.value ? this.statusChoose.value : "") : ""
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      const app = this
      app.statusChoose = ''
      app.metaFilter.stock_transfer = ''
      app.metaFilter.status = ''
      app.metaFilter.search = ''
      await app.handleFind()
    }
  }
}
</script>
