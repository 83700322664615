<template>
  <div class="uk-child-width-expand@s uk-grid-small uk-margin-top">
    <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-4@l">
      <button
        v-if="isCanAccessUser(`add`, `Permintaan Sapronak`)"
        class="uk-button uk-button-default uk-width-expand uk-width-auto@s uk-margin-right"
        @click="$router.push(`/admin/sapronak/import`)"
      >
        <span
          uk-icon="icon: upload; ratio: 0.7"
          class="uk-margin-small-right"
        />Import
      </button>
    </div>
  </div>
</template>

<script>
import { isCanAccess } from '@/utils/auth'

export default {
  methods: {
    isCanAccessUser(type, menu){
      return isCanAccess(type, menu)
    }
  }
}
</script>

<style scooped>
    .border-import {
        border-top: 1px solid #e0ebe6; 
        padding-top: 20px
    }
</style>
